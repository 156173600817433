<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(../assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">會員中心</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                ＄幣兌換中心
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-4">
            <div class="card bg-warning card-hover mb-3">
              <div class="card-body text-center px-md-5 px-lg-6 my-2">
                <div class="card-icon-border-large border-warning mtn-80">
                  <img src="@/assets/img/avator/avator-img1.jpg" alt="testimonial1.jpg" v-if="reloginImg === 'null'">
                  <img :src="reloginImg" alt="">
                </div>
                <blockquote class="blockquote blockquote-sm mt-2">
                  <!-- <label class="btn btn-link">
                    <input id="upload_img" style="display: none" type="file" />
                    <i class="fa fa-plus-circle"></i> <span>上傳圖片</span>
                  </label> -->

                  <footer
                    class="blockquote-footer text-uppercase text-white font-size-20 mb-3"
                  >
                    {{ gradeTitle }}
                  </footer>
                  <ul class="member__level-buttons">
                    <li v-for="(item, index) in gradeMap" :key="index" class="m-1">
                      <button @click="$router.push(item.link)" :disabled="form.grade < item.grade" type="button" class="btn btn-light mb-1">
                        {{ `${item.title}測驗` }}
                      </button>
                    </li>
                  </ul>
                </blockquote>
              </div>
            </div>

            <ul class="list-unstyled mb-0">
              <li class="mb-2">
                <router-link to="/member"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  基本資料
                </router-link>
              </li>
              <li class="my-2">
                <router-link :to="grade === 0? '/fqtest' : '/member/myfqage'"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  我的FQ年齡
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/mypoints"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  我的$幣存摺
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/mall"
                  class="text-warning font-weight-medium d-block border rounded py-2 pl-3">
                  $幣兌換中心
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/exchange"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  $幣累兌明細
                </router-link>
              </li>
              <li class="my-2">
                <button type="button"
                  @click="logOut"
                  class="btn btn-block text-muted font-weight-medium  border rounded py-2 pl-3">
                  會員登出
                </button>
              </li>
            </ul>
          </div>
          <div class="col-md-8 col-lg-8 order-md-1">
            <form class="" action="index.html" method="post">
              <div class="card shadow-none">
                <div
                  class="card-header card-header-lg bg-warning text-white p-3 rounded-top d-flex justify-content-between align-items-baseline"
                >
                  <h4 class="font-weight-bold mb-0">我的＄幣：{{ totalPoints }} 元</h4>
                </div>

                <div
                  class="card-body border border-top-0 rounded-bottom-sm p-7"
                >
                  <div class="row">
                    <div v-for="(prize, index) in prizeList" class="col-md-6" :key="index">
                      <div class="card card-product border-warning">
                        <div class="text-center py-4">
                          <img
                            :src="prize.img"
                            class="img-fluid w-60"
                            alt="Card image"
                          />
                        </div>

                        <div class="card-body bg-warning px-5 py-6">
                          <h4 class="mb-1 text-white">{{ prize.name }}</h4>
                          <h5 class="d-block mb-2">
                            兌換：<span class="font-size-24">{{ prize.point }}</span> ＄幣
                          </h5>
                          <a
                            @click.prevent="changePrize(prize.name, prize.name, prize.point)"
                            href="#"
                            class="btn btn-sm btn-white text-hover-warning text-uppercase d-inline-block"
                          >
                            <i
                              class="fa fa-shopping-basket mr-2"
                              aria-hidden="true"
                            ></i
                            >我要兌換
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6">
                    <!-- PAGINATION -->
                    <!-- <section class="py-5">
                      <div class="container">
                        <nav aria-label="Page navigation example">
                          <nav aria-label="Page navigation example">
                            <ul
                              class="pagination justify-content-center align-items-center"
                            >
                              <li class="page-item">
                                <a class="page-link" href="#">
                                  <i
                                    class="fa fa-arrow-left mr-1"
                                    aria-hidden="true"
                                  ></i>
                                  Prev
                                </a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">1</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">2</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">3</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">...</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">15</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">
                                  Next
                                  <i
                                    class="fa fa-arrow-right ml-1"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </nav>
                      </div>
                    </section> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
import richkidConfig from '@/assets/json/richkid.config.json'
export default {
  name: 'mall',
  data () {
    return {
      memberInfo: {
        memberId: '',
        name: '',
        email: '',
        mobile: '',
        address: ''
      },
      reloginImg: '',
      totalPoints: '',
      gradeTitle: '',
      form: {},
      grade: '',
      prizeList: []
    }
  },
  created () {
    const vm = this

    if (localStorage.getItem('memberToken') === '') {
      alert('請先登入')
      vm.$router.push('/login')
      return false
    }

    vm.reloginImg = localStorage.getItem('reloginImg')
    vm.getPoints()
    vm.getMember()
    vm.getPrizeList()
    vm.gradeTitle = sessionStorage.getItem('gradeTitle')
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  computed: {
    gradeMap () {
      return richkidConfig.data.grade
    }
  },
  methods: {
    getMember () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`
      vm.memberToken = localStorage.getItem('memberToken')

      vm.axios.post(api, {
        token: tokenGenerator(),
        memberToken: vm.memberToken
      })
        .then((res) => {
          vm.form = res.data.content
          vm.grade = res.data.content.grade
        })
    },
    changePrize (prizeName, prizeDescription, prizePoints) {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/prizes/add`

      vm.getMemberInfo().then(() => {
        vm.axios.post(api, {
          ...vm.memberInfo,
          token: tokenGenerator(),
          prizename: prizeName,
          description: prizeDescription,
          points: prizePoints
        })
          .then((res) => {
            if (res.data.status === '000') {
              if (res.data.errmsg !== '') {
                alert(res.data.errmsg)
              } else {
                alert('兌換成功!')
              }
            } else {
              alert('兌換有誤!')
            }
          })
      })
    },
    getMemberInfo () {
      return new Promise((resolve, reject) => {
        const vm = this
        const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

        vm.axios.post(api, {
          token: tokenGenerator(),
          memberToken: localStorage.getItem('memberToken')
        })
          .then((res) => {
            vm.memberInfo.memberId = res.data.content.id
            vm.memberInfo.name = res.data.content.name
            vm.memberInfo.email = res.data.content.email
            vm.memberInfo.mobile = res.data.content.mobile
            vm.memberInfo.address = res.data.content.address
            resolve()
          })
      })
    },
    getPoints () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

      vm.axios.post(api, {
        token: tokenGenerator(),
        memberToken: localStorage.getItem('memberToken')
      })
        .then((res) => {
          vm.totalPoints = res.data.content.total_points
        })
    },
    getPrizeList () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/newPrizes/list`

      vm.axios.post(api, {
        token: tokenGenerator(),
        page: 1,
        pageSize: 9999
      })
        .then((res) => {
          vm.prizeList = res.data.content.data
        })
    },
    logOut () {
      localStorage.removeItem('token')
      localStorage.removeItem('memberId')
      localStorage.removeItem('memberToken')
      localStorage.removeItem('reloginImg')

      location.href = '/'
    }
  }
}
</script>

<style lang="scss" scoped>
  .member__level-buttons {
    padding-left: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1rem 0;
  }
</style>
